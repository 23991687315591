'use strict';

angular.module('windmanagerApp')
  .directive('addSite', function () {
    return {
      templateUrl: 'components/directives/site/addSite/addSite.html',
      restrict: 'EA',
      controller: 'AddEditSiteCtrl',
      controllerAs: 'addSite',
      link: function (scope, element, attrs) {
      }
    };
  });
